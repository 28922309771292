<template>
  <div>
    <el-dialog
      :title="title + '权限'"
      :visible.sync="showing"
      width="550px"
      min-height="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <!-- 一个表单 -->
        <el-form
          ref="formInline"
          :model="formInline"
          @submit.native.prevent
          :rules="rules"
        >
          <div class="banbentwoItem">
            <el-form-item label="编号" prop="code">
              <el-input
                ref="code"
                v-model="formInline.code"
                placeholder="请输入编号"
                maxlength="20"
                type="text"
              ></el-input>
            </el-form-item>
          </div>

          <div class="banben">
            <div class="bandanItem">
              <!-- <el-form-item label="模块编号" prop="name">
                <el-input
                  ref="code"
                  maxlength="20"
                  v-model="formInline.name"
                  placeholder="请输入模块编号"
                  type="text"
                ></el-input>
              </el-form-item> -->
              <el-form-item label="状态" prop="rightStatus">
                <el-radio-group v-model="formInline.rightStatus">
                  <el-radio :label="0">开启</el-radio>
                  <el-radio :label="1">关闭</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="bandanItem">
              <el-form-item label="显示顺序" prop="dispOrder">
                <el-input
                  ref="code"
                  v-model="formInline.dispOrder"
                  placeholder="请输入显示顺序"
                  maxlength="20"
                  type="text"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="banbentwo">
            <div class="banbentwoItem">
              <el-form-item label="说明" prop="description">
                <el-input
                  ref="code"
                  maxlength="20"
                  v-model="formInline.description"
                  placeholder="请输入说明"
                  type="textarea"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="sure">
            <el-button
              size="small"
              type="primary"
              @click="createStorage('formInline')"
              >确定</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getRequest, postRequest } from "@/api/api.js";
import { regMatch } from "../../utils/reg";
export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      bedisabled: false,
      index: null,
      infor: {},
      modeName: null,
      modeCode: null,
      //表单
      formInline: {
        rightStatus: 0,
      },
      disabled: true,
      add: true,
      //表单验证
      rules: {
        code: [
          {
            required: true,
            message: "请输入编号，不能为空",
            trigger: "blur",
          },
        ],
        dispOrder: [
          {
            required: true,
            message: "请输入显示顺序，不能为空",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "请输入说明，不能为空",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: "请输入排序，不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["state"],
  created() {
    var mode = this.$route.query;
    console.log("123", this.$route.query);
    this.modeCode = mode.code;
    this.modeName = mode.name;
    this.sort = mode.sort;
  },
  methods: {
    hide() {
      this.fileListUpload = [];
      this.showing = false;
    },
    show(item, val, row) {
      this.title = item;
      this.showing = true;
      this.formInline = {
        rightStatus: 0,
      };
      console.log(6666, val);
      // this.infor = val;
      this.add = false;
      if (item == "编辑") {
        this.formInline = JSON.parse(JSON.stringify(row));
        this.add = true;
      }
    },
    createStorage(formData) {
      // 表单验证-----------
      this.$refs[formData].validate((valid) => {
        if (valid) {
          if (this.title == "创建") {
            let loadingInstance = this.$loading({ text: "新增中..." }); //如果这数据不存在，则要去接口请求数据
            this.formInline.moduleNo = this.$route.query.moduleNo;
            postRequest("/insertSysRight", this.formInline).then((res) => {
              this.$nextTick(() => {
                loadingInstance.close();
              }); //不回调
              if (res.status == 200) {
                this.$message({
            duration: 5000,
                  message: "新增成功!",
                  type: "success",
                });
                this.hide();
                this.$parent.getFounderList();
              } else {
                this.$message({
            duration: 5000,
                  message: res.message,
                  type: "warning",
                });
              }
            });
          } else {
            this.updateStation(); //调用编辑函数
          }
        } else {
          this.$message({
            duration: 5000,
            message: "请仔细核对填写的内容！",
            type: "warning",
          });
        }
      });
    },
    // 编辑
    updateStation() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("/updateSysRight", this.formInline).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        }); //不回调
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "修改成功!",
            type: "success",
          });
          this.hide();
          this.$parent.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 320px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.el-button:hover {
  transform: scale(1.1);
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.banbentwo {
  width: 100%;
}
.banbentwoItem {
  width: 100%;
  margin-bottom: 16px;
}
.banbentwoItem > input {
  width: 100%;
  height: 40px !important;
  background: #fefeff;
  box-sizing: border-box;
  border: 1px solid #e8ecef;
  border-radius: 4px;
  padding-left: 15px;
  outline: none;
}
.banbentwoItem > div:first-child {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #000000;
  opacity: 0.65;
}
.el-radio__inner {
  height: 24px !important;
  width: 24px !important;
}
.el-radio__inner::after {
  height: 10px;
  width: 10px;
}
input {
  opacity: 0.65;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.bandanItem {
  display: flex;
  flex-direction: column;
  width: 242px;
  height: 74px;
  margin-bottom: 16px;
}
.bandanItem > div {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #000000;
  opacity: 0.65;
}
.bandanItem > input {
  width: 100%;
  height: 40px !important;
  background: #fefeff;
  box-sizing: border-box;
  border: 1px solid #e8ecef;
  border-radius: 4px;
  padding-left: 15px;
  outline: none;
}
.bandanItem > .el-date-picker {
  width: 100%;
  height: 40px;
}
.bandanItem > select {
  width: 100%;
  height: 40px !important;
  background: #fefeff;
  box-sizing: border-box;
  border: 1px solid #e8ecef;
  border-radius: 4px;
  padding-left: 15px;
  outline: none;
}
</style>